<template>
<div>

    <v-container>
        <v-row>
            <v-col cols="12">
                <s-toolbar :color="'#244093'" dark label="Reporte Exigibles" class="tran"></s-toolbar>
                <v-card>

                    <v-row style="margin-top: 4px; margin-left: 10px; margin: auto">
                        <v-col cols="6" lg="4" md="6">
                            <s-select-definition :disabled="disabled" :def="1161" label="Tipo" v-model="TypeDescription"></s-select-definition>
                        </v-col>

                        <v-col cols="6" lg="3" md="3">
                            <s-text label="Codigo de venta" v-model="CodigoVenta"></s-text>
                        </v-col>
                        <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                            <v-btn elevation="0" :color="'success'" small @click="runView()">Buscar</v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <!-- <s-toolbar :color="'#244093'" dark label="Reporte Exigibles" class="tran"></s-toolbar> -->
                <v-row style="margin: auto">
                    <v-card width="100%">

                        <v-row style="margin: auto">
                            <!-- <v-col cols="6" lg="2" md="2">
                                <v-btn width="100%" rounded :color="'info'" small @click="runView()">Buscar</v-btn>
                            </v-col> -->
                            <v-col cols="12" lg="12" md="12">
                                <v-btn elevation="0" style="text-transform: capitalize;" class="mr-2" :color="'error'" x-small @click="downloadReport()">Descargar All</v-btn>
                                <v-btn elevation="0" style="text-transform: capitalize;" :color="'info'" x-small @click="downloadReportResumen()">Descargar Resumen</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
    <v-container>

        <v-tabs v-model="currentItem">
            <v-tab href="#Dashboard"> Dashboard</v-tab>
            <v-tab href="#ReportMoor"> Reporte Exigibles</v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentItem">
            <v-tab-item :value="'Dashboard'">
                <clc-report-payable-dashboard :itemsDashboardInteres="itemsDashboardInteres" :itemsDashboard="dataDashboard"></clc-report-payable-dashboard>

            </v-tab-item>

            <v-tab-item :value="'ReportMoor'">
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-data-table :height="items.length > 0 ? '400px': 'auto'" :headers="headers" :items="items" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                            </v-data-table>
                            <!--  
                         -->
                            <!-- <s-crud :config="config" :filter="filter" excel pdf no-full  noSearch @rowSelected="rowSelected($event)">
                            </s-crud> -->
                        </v-col>
                    </v-row>
                </v-col>
            </v-tab-item>

        </v-tabs-items>

        <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
            <v-card color="primary" dark>
                <v-card-text v-if="messageProcessing.length == 0">
                    Por favor espere
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text--white pt-4">
                        Error al realizar busqueda <br />
                        {{ messageProcessing }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</div>
</template>

<script>
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import ClcReportPayableDashboard from './ClcReportPayableDashboard.vue';

export default {
    components: {
        ClcReportPayableDashboard
    },
    data() {
        return {
            currentItem: "tab-Funciones",
            /**/

            /* */
            items: [],
            headers: [],
            report: {},
            processing: false,
            messageProcessing: "",
            TypeDescription: 4,
            disabled: false,
            CodigoVenta: null,

            group: [],
            dataDashboard: [],
            itemsDashboardInteres: [],
        }

    },

    watch: {
        CodigoVenta() {
            if (this.CodigoVenta.length == 0) {
                this.disabled = false
                this.CodigoVenta = null
            } else {
                this.disabled = true
                this.TypeDescription = 4
            }

        }
    },
    methods: {

        rowSelected() {

        },

        runView() {

            this.messageProcessing = ""
            this.report.QryNameProcedure = "";
            this.report.QryParamsArray = "Month, TypeDescription, CodigoVenta"
            this.report.QryParamsArrayValue = "Mes, " + this.TypeDescription + ', ' + this.CodigoVenta //this.DateBegin + "," + this.DateEnd + "," + this.number;
            this.report.QryNameProcedure = "ClcReportPayableResumen_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(this.report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.items = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.items[0]).length; i++
                            ) {
                                this.headers.splice(i, i, {
                                    text: Object.keys(this.items[0])[i],
                                    value: Object.keys(this.items[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                            //*********************** DATA PARA DASHBOARD ********************/
                            var sumCapital = 0,
                                sumInteres = 0,
                                objTmp = {},
                                objTmp2 = {}

                            this.dataDashboard = []
                            this.itemsDashboardInteres = []

                            this.group = _.groupBy(this.items, "exigible");

                            for (let prop in this.group) {
                                sumCapital = 0
                                sumInteres = 0
                                objTmp = {}
                                objTmp2 = {}

                                this.group[prop].map((i) => {
                                    sumCapital = sumCapital + i.capitalcrono
                                    sumInteres = sumInteres + i.interescrono
                                });

                                //GRAFICO PIE AÑOS
                                objTmp.name = prop
                                objTmp.y = sumCapital
                                objTmp.drilldown = prop

                                objTmp2.name = prop
                                objTmp2.y = sumInteres
                                objTmp2.drilldown = prop

                                this.dataDashboard.push(objTmp)
                                this.itemsDashboardInteres.push(objTmp2)

                            }

                            //************************** FIN  *************************************/

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        downloadReport() {
            this.report = {}
            this.messageProcessing = ""
            this.report.QryNameProcedure = "";
            this.report.QryParamsArray = "Month"
            this.report.QryParamsArrayValue = "mes" //this.DateBegin + "," + this.DateEnd + "," + this.number;
            this.report.QryNameProcedure = "ClcReportPayable_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(this.report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {

                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte Exigibles"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },
        downloadReportResumen() {
            this.report = {}
            this.messageProcessing = ""
            this.report.QryNameProcedure = "";
            this.report.QryParamsArray = "Month, TypeDescription"
            this.report.QryParamsArrayValue = "mes, " + +this.TypeDescription //this.DateBegin + "," + this.DateEnd + "," + this.number;
            this.report.QryNameProcedure = "ClcReportPayableResumen_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(this.report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {

                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte Exigibles - Resumen"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        }
    }
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
